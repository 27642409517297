import blog1_v1 from "../assets/blog1_esc_img1.jpeg"
import blog1_v2 from "../assets/blog1_esc_img2.jpeg"
import blog2_v1 from "../assets/blog2_esc_img1.jpeg"
import blog3_v1 from "../assets/blog3_esc_img1.jpeg"
import blog3_v2 from "../assets/blog3_esc_img2.jpeg"
import blog3_v3 from "../assets/blog3_esc_img3.jpeg"
import blog4_v1 from "../assets/empresarios-rrhh.jpg"
import videob4 from "../assets/videob4_esc_1.mp4"
import blogv5_v1 from "../assets/performance_equipos_esc.png"
import videb5 from "../assets/videob5_esc_1.mp4"
import blog6_v1 from "../assets/rodrigo_scerpella_esc.jpeg"
import videob6 from "../assets/videob6_esc_1.mp4"
import blogv7_v1 from "../assets/valeria_arbulu_esc.jpeg"
import videob7 from "../assets/videob7_esc_1.mp4"
import blogv8 from "../assets/blog_convenios.jpeg"
import blogv9 from "../assets/srazapata.jpg"
import videob9 from "../assets/video_zapata_esc_v2.mp4"
import blog10_v1 from "../assets/blog10_v1.jpeg"
import blog10_v2 from "../assets/blog10_v2.jpeg"
import blog10_v3 from "../assets/blog10_v3.jpeg"
import blog10_v4 from "../assets/blog10_v4.jpeg"
import blog10_v5 from "../assets/blog10_v5.jpeg"
import blog10_v6 from "../assets/blog10_v6.jpeg"
import blog10_v7 from "../assets/blog10_v7.jpeg"
import blog10_v8 from "../assets/blog10_v8.jpeg"

export const blogs = [
  {
    id: 1,
    url: "/blog/somos-esc",
    title: "Somos ESC: Escucha, Soluciona y Crece - Consultora de Recursos Humanos",
    img: {
      content: blog1_v1,
      alt: "Somos ESC: Escucha, Soluciona y Crece - Consultora de Recursos Humanos",
    },
    body: [
      {
        type: "text",
        content:
          "¡Hola! Somos ESC Escucha, Soluciona y Crece, la consultora de RRHH del Grupo Eades. Te escuchamos desde un punto de vista psicológico y analítico para poder solucionar los problemas internos de tu empresa y finalmente ayudarte a crecer en rentabilidad.",
      },
      {
        type: "text",
        content:
          "Nos especializamos en potenciar el clima y cultura que tu empresa necesita, a través de soluciones que se enfoquen en la salud mental. ",
      },
      {
        type: "text",
        content:
          "Nuestros servicios son: ",
      },
      {
        type:"text_list",
        content:[
          "Reclutamiento y selección de personal",
          "Medición e intervención en el Clima y Cultura Laboral",
          "Capacitaciones, charlas y talleres",
          "Counselling y mediación de conflictos"
        ]
      },
      {
        type: "text",
        content:
          "Nos especializamos en escuchar a cada individuo y resolver conflictos, creando un entorno y una cultura empresarial que impulsan al crecimiento rentable.",
      },
      {
        type:"text",
        content:"Recuerda, cualquier duda o consulta puedes escribirnos con toda confianza al +51 920 188 134"
      },
      {
        type:"img_list",
        content:[
          {
            img:blog1_v1,
            alt:"Somos Ilumina - Asociación sin fines de lucro"
          },
          {
            img:blog1_v2,
            alt:"Somos Ilumina - Asociación sin fines de lucro"
          }
        ]
      }
    ],
  },
  {
    id: 2,
    url: "/blog/estres-laboral",
    title: "Estrés laboral 😣",
    img: {
      content: blog2_v1,
      alt: "Estrés laboral 😣",
    },
    body: [
      {
        type: "text",
        content:
          "Reconocemos que el estrés laboral puede representar un desafío extremadamente difícil. Te levantas casi diariamente con pendientes laborales, reuniones prolongadas y la constante presión de cumplir con plazos realmente cortos. La falta de un balance entre la vida personal y laboral puede generar que sientas una gran desmotivación...",
      },
      {
        type:"text",
        content:"En ESC, somos conscientes de la importancia de la salud mental de los trabajadores para que alcancen su mayor desempeño y potencial dentro de la empresa. Con nuestro enfoque clínico-organizacional, hemos acompañado a muchas empresas a crear ambientes de trabajo más saludables, empáticos y productivos. Queremos hacerlo contigo también..."
      },
      {
        type:"text",
        content:"Sabemos que los cambios reales requieren acciones concretas, te compartimos algunos tips:"
      },
      {
        type:"text_list",
        content:[
          "💥 Reducción de estrés: Implementamos técnicas y programas que ayudan a reducir el estrés desde la raíz.",
          "🤝 Cultura Organizacional Saludable: Fomentamos un ambiente donde la escucha activa, el trabajo en equipo y la comunicación efectiva son piezas fundamentales en la empresa.",
          "👥 Apoyo Psicológico: Ofrecemos acceso a recursos y profesionales que pueden ayudarte a manejar la presión y exigencias laborales de manera efectiva."
        ]
      },
      {
        type:"text",
        content:"No dejes que el trabajo te consuma. Si te identificas con esto, es hora de hacer un cambio. Nuestra misión es ayudarte a encontrar ese equilibrio entre la vida laboral y personal, y asegurar que cada día te sientas más fuerte y menos estresado."
      },
      {
        type:"text_links",
        content:[
          {
            text:"#EstrésLaboral",
            url:"https://www.linkedin.com/feed/hashtag/?keywords=estr%C3%A9slaboral&highlightedUpdateUrns=urn%3Ali%3Aactivity%3A7228920726471204864"
          },
          {
            text:"#BienestarEnElTrabajo",
            url:"https://www.linkedin.com/feed/hashtag/?keywords=bienestareneltrabajo&highlightedUpdateUrns=urn%3Ali%3Aactivity%3A7228920726471204864"
          },
          {
            text:"#RecursosHumanos",
            url:"https://www.linkedin.com/feed/hashtag/?keywords=recursoshumanos&highlightedUpdateUrns=urn%3Ali%3Aactivity%3A7228920726471204864"
          },
          {
            text:"#VidaSaludable",
            url:"https://www.linkedin.com/feed/hashtag/?keywords=vidasaludable&highlightedUpdateUrns=urn%3Ali%3Aactivity%3A7228920726471204864"
          }
        ]
      },
      {
        type:"text",
        content:"¡Comparte tu experiencia o contacta con nosotros para saber más!"
      },
      {
        type:"img",
        content:blog2_v1,
        alt:"Estrés laboral 😣 - ESC"
      }
    ],
  },
  {
    id: 3,
    url: "/blog/habitos-alimenticios-estres-laboral",
    title: "Hábitos alimenticios y el estrés laboral",
    img: {
      content: blog3_v1,
      alt: "Hábitos alimenticios y el estrés laboral",
    },
    body: [
      {
        type: "text",
        content:
          "Una alimentación saludable en la oficina aumenta la energía y concentración, mejorando el rendimiento y estado de ánimo. ",
      },
      {
        type:"text",
        content:"Optar por alimentos nutritivos reduce el riesgo de enfermedades y potencia la productividad. Comenzar a tomar conciencia sobre una alimentación saludable, genera un clima laboral más eficaz y satisfactorio."
      },
      {
        type:"text_list",
        content:[
          "No tener una dieta balanceada reduce tu energía y concentración",
        ]
      },
      {
        type:"img",
        content:blog3_v1,
        alt:"Reduce energía y concentración"
      },
      {
        type:"text_list",
        content:[
          "Aumenta tu estrés",
        ]
      },
      {
        type:"img",
        content:blog3_v2,
        alt:"Aumenta tu estrés"
      },
      {
        type:"text_list",
        content:[
          "Disminuye tu bienestar y motivación",
        ]
      },
      {
        type:"img",
        content:blog3_v3,
        alt:"Disminuye tu bienestar y motivación"
      },
      {
        type:"text",
        content:"Mantente informado con nuestros tips y recuerda si te gustaría tener una asesoría puedes contar con nuestro equipo de profesionales, escríbenos con toda confianza al +51 920 188 134."
      },
      {
        type:"text_links",
        content:[
          {
            text:"#empresa",
            url:"https://www.instagram.com/explore/tags/empresa/"
          },
          {
            text:"#estres",
            url:"https://www.instagram.com/explore/tags/estres/"
          },
          {
            text:"#saludcorporativa",
            url:"https://www.instagram.com/explore/tags/saludcorporativa/"
          },
          {
            text:"#rendimientolaboral",
            url:"https://www.instagram.com/explore/tags/rendimientolaboral/"
          },
          {
            text:"#alimentacionsaludable",
            url:"https://www.instagram.com/explore/tags/alimentacionsaludable/"
          },
          {
            text:"#climalaboral",
            url:"https://www.instagram.com/explore/tags/climalaboral/"
          },
          {
            text:"#productividad",
            url:"https://www.instagram.com/explore/tags/productividad/"
          },
          {
            text:"#saludybienestar",
            url:"https://www.instagram.com/explore/tags/saludybienestar/"
          },
          {
            text:"#bienestarintegral",
            url:"https://www.instagram.com/explore/tags/bienestarintegral/"
          }
        ]
      },
    ],
  },
  {
    id: 4,
    url: "/blog/trabajadores-felices",
    title: "Trabajadores felices",
    img: {
      content: blog4_v1,
      alt: "Trabajadores felices",
    },
    body: [
      {
        type: "text",
        content:
          "¿Quieres llevar tu empresa al siguiente nivel? La clave está en la felicidad de tus empleados🙌",
      },
      {
        type: "text",
        content:
          "Preocuparnos por el ambiente laboral de nuestros empleados es vital porque genera una mayor motivación, creatividad e innovación para cumplir objetivos.",
      },
      {
        type:"text_list",
        content:[
          "📌Reduce el estrés, minimizando errores, ausencias y rotación.",
          "📌 Crea relaciones laborales positivas fomentando un trabajo en equipo sin fricciones.",
          "📌 Brindan un mejor servicio al cliente, aumentando la fidelidad y preferencia."
        ]
      },
      {
        type: "text",
        content:
          "👥Un ambiente de trabajo feliz no solo es una buena práctica, es una estrategia empresarial que se refleja en el éxito de tu empresa.",
      },
      {
        type:"text",
        content:"¡Haz de la felicidad de tus empleados tu mejor inversión y observa cómo crece tu empresa!"
      },
      {
        type:"video_inst",
        content:videob4
      }
    ],
  },
  {
    id: 5,
    url: "/blog/cultura-de-colaboracion",
    title: "Cultura de Colaboración",
    img: {
      content: blogv5_v1,
      alt: "Cultura de Colaboración",
    },
    body: [
      {
        type: "text",
        content:
          "Escucha, crece y aprende.",
      },
      {
        type:"text",
        content:"Te compartimos 3 prácticas infalibles para fomentar la Cultura de Colaboración en tu empresa ⚡️"
      },
      {
        type:"text",
        content:"Nuestro especialista Mateo te comparte estos consejos para mejorar el trabajo en equipo."
      },
      {
        type:"text",
        content:"Éxito asegurado."
      },
      {
        type:"text_links",
        content:[
          {
            text:"#colaboracion",
            url:"https://www.instagram.com/explore/tags/colaboracion/"
          },
          {
            text:"#empresa",
            url:"https://www.instagram.com/explore/tags/empresa/"
          },
          {
            text:"#saludcorporativa",
            url:"https://www.instagram.com/explore/tags/saludcorporativa/"
          },
          {
            text:"#recursoshumanos",
            url:"https://www.instagram.com/explore/tags/recursoshumanos/"
          },
          {
            text:"#consultoria",
            url:"https://www.instagram.com/explore/tags/consultoria/"
          },
          {
            text:"#climalaboral",
            url:"https://www.instagram.com/explore/tags/climalaboral/"
          },
          {
            text:"#saludmental",
            url:"https://www.instagram.com/explore/tags/saludmental/"
          },
          {
            text:"#equipo",
            url:"https://www.instagram.com/explore/tags/equipo/"
          },
          {
            text:"#trabajoenequipo",
            url:"https://www.instagram.com/explore/tags/trabajoenequipo/"
          },
          {
            text:"#saludlaboral",
            url:"https://www.instagram.com/explore/tags/saludlaboral/"
          },
          {
            text:"#bienestarlaboral",
            url:"https://www.instagram.com/explore/tags/bienestarlaboral/"
          },
          {
            text:"#productividad",
            url:"https://www.instagram.com/explore/tags/productividad/"
          },
          {
            text:"#exito",
            url:"https://www.instagram.com/explore/tags/trabajoenequipo/"
          },
          {
            text:"#mejoresresultados",
            url:"https://www.instagram.com/explore/tags/mejoresresultados/"
          }
        ]
      },
      {
        type:"video_inst",
        content:videb5
      }
    ],
  },
  {
    id: 6,
    url: "/blog/testimonio-rodrigo-scerpella",
    title: "Testimonio de Rodrigo Scerpella,",
    img: {
      content: blog6_v1,
      alt: "Business developer de la empresa Pacific",
    },
    body: [
      {
        type: "text",
        content:
          "«El principal cambio que veo es que las personas automáticamente entendieron que todo el trabajo que realizamos con la consultora es para su beneficio profesional y personal. Se sienten más valorados, reconocidos, escuchados y considerados. El ambiente ya empezó a sentirse diferente y estos primeros cambios ya nos hacen ver a futuro con mucho entusiasmo.»",
      },
      {
        type:"video_inst",
        content:videob6
      }
    ],
  },
  {
    id: 7,
    url: "/blog/testimonio-valeria-arbulu",
    title: "Testimonio de Valeria Arbulú, Gerente de Recursos Humanos de Sierra Muebles Perú.",
    img: {
      content: blogv7_v1,
      alt: "Testimonio de Valeria Arbulú, Gerente de Recursos Humanos de Sierra Muebles Perú.",
    },
    body: [
      {
        type: "text",
        content:
          "«Me pareció un servicio bastante completo porque se dedicaron de lleno a cada uno de los colaboradores. Les dieron el tiempo que necesitaban y les abrieron el espacio al diálogo sin limitaciones. Además, algo que valoro mucho por encima de otras consultoras es el criterio clínico que aportan en sus evaluaciones y durante todo su enfoque, asegurando el cuidado integral de cada colaborador.»",
      },
      {
        type:"video_inst",
        content:videob7
      }
    ],
  },
  {
    id: 8,
    url: "/blog/esc-escucha-soluciona-crece-se-unio-a-la-camara-de-comercio",
    title: "ESC: Escucha, Soluciona y Crece se ha unido a la Cámara de Comercio de Lima y a la Asociación de Exportadores del Perú (ADEX).",
    img: {
      content: blogv8,
      alt: "ESC: Escucha, Soluciona y Crece se ha unido a la Cámara de Comercio de Lima y a la Asociación de Exportadores del Perú (ADEX).",
    },
    body: [
      {
        type: "text",
        content:
          "Este nuevo capítulo marca un paso importante en nuestro compromiso de ofrecer servicios de recursos humanos de alta calidad y relevancia para nuestros clientes. La incorporación a estas prestigiosas instituciones no solo fortalece nuestra posición en el mercado, sino que también ofrece una serie de beneficios clave para quienes confían en nuestros servicios:",
      },
      {
        type:"title",
        content:"Beneficios para Nuestros Clientes:"
      },
      {
        type:"text_list_ol",
        content:[
          {
            title:"Acceso a Redes Empresariales de Alto Nivel: ",
            text:"Al ser miembros de la Cámara de Comercio de Lima y ADEX, tenemos acceso a una red extensa de empresas y profesionales líderes en diversos sectores. Esto nos permite conectar a nuestros clientes con potenciales socios, clientes y oportunidades de negocio, facilitando así el crecimiento y la expansión de sus operaciones, participando de las próximas charlas y talleres que estaremos organizando. "
          },
          {
            title:"Información Actualizada y Relevante: ",
            text:"Ambas instituciones están al tanto de las últimas tendencias, regulaciones y mejores prácticas en el ámbito empresarial. Como socios, podemos ofrecer a nuestros clientes información actualizada y relevante sobre el mercado y el entorno regulatorio, lo que les ayudará a tomar decisiones más informadas y estratégicas."
          },
          {
            title:"Oportunidades de Capacitación y Desarrollo:",
            text:"La Cámara de Comercio de Lima y ADEX organizan eventos de capacitación sobre diversos temas empresariales, que nosotros replicaremos a través de nuestras propios Seminarios y Talleres. Nuestros clientes se beneficiarán de la oportunidad de participar en estas actividades, ampliando sus conocimientos y habilidades en áreas clave como gestión en el área humana, crecimiento profesional, habilidades blandas y liderazgo."
          },
          {
            title:"Visibilidad y Reconocimiento:",
            text:"La asociación con estas instituciones prestigiosas fortalece nuestra reputación y la de nuestros clientes. A través de nuestras conexiones y el respaldo de la Cámara de Comercio de Lima y ADEX, nuestros clientes ganan mayor visibilidad y reconocimiento en el mercado, lo que puede traducirse en nuevas oportunidades de negocio."
          },
          {
            title:"Acceso a Recursos Exclusivos: ",
            text:"Contar con el respaldo de estas instituciones nos permite acceder a recursos y herramientas exclusivas que compartimos con nuestros clientes, desde estudios de mercado hasta informes sectoriales, que les ayudan a mantenerse competitivos en un entorno dinámico."
          }
        ]
      },
      {
        type:"text",
        content:"En ESC: Escucha, Soluciona y Crece, estamos comprometidos con el éxito de nuestros clientes; y esta colaboración con la Cámara de Comercio de Lima y ADEX es un reflejo de nuestra dedicación a ofrecerles el mejor apoyo y las mayores oportunidades. Estamos entusiasmados por las nuevas posibilidades que esta asociación abre para todos y esperamos seguir creciendo juntos."
      },
      {
        type:"text",
        content:"¡Gracias por ser parte de nuestra comunidad y por confiar en nosotros para acompañarlos en su camino hacia el éxito!"
      }
    ],
  },
  {
    id: 9,
    url: "/blog/sra-zapata",
    title: "Compartimos testimonio de Sra. Zapata",
    img: {
      content: blogv9,
      alt: "Compartimos testimonio de Sra. Zapata",
    },
    body: [
      {
        type: "text",
        content:
          "Compartimos testimonio de Sra. Zapata, quien nos comparte lo importante que es hacer un viaje introspectivo repasando campos de la mente y de las emociones. Brindamos una sesión personalizada, adaptándonos a sus necesidades. ",
      },
      {
        type: "text",
        content:
          "Recuerda que estamos para atenderte junto a nuestro equipo de profesionales. Puedes escribirnos con toda confianza al 920-188-134 o a nuestro correo contacto@escconsultora.com.",
      },
      {
        type:"video_inst",
        content:videob9
      }
    ],
  },
  {
    id:10,
    url:"/blog/taller-de-liderazgo-para-sierra-muebles",
    title:"Taller de Liderazgo para Sierra Muebles",
    img:{
      content:blog10_v1,
      alt:"Taller de Liderazgo para Sierra Muebles"
    },
    body:[
      {
        type: "text",
        content:
          "En nuestro reciente Taller de Liderazgo para Sierra Muebles, exploramos estrategias clave para fortalecer la comunicación asertiva y adaptarnos a las diferencias individuales en el equipo. Implementamos técnicas prácticas como un Manual de Incentivos y actividades de reconocimiento, fomentando un ambiente colaborativo y motivador.",
      },
      {
        type: "text",
        content:
          "A través del Modelo VAK, personalizamos la comunicación y tareas para maximizar el potencial de cada miembro, mientras que las técnicas de los 3 Filtros de Sócrates nos ayudaron a refinar nuestras interacciones. El resultado fue un equipo más alineado, motivado y preparado para alcanzar sus objetivos.",
      },
      {
        type: "text",
        content:
          "¡Te invitamos a ver algunas fotos de este dinámico taller!",
      },
      {
        type:"img_list",
        content:[
          {
            img:blog10_v1,
            alt:"Taller de Liderazgo para Sierra Muebles"
          },
          {
            img:blog10_v2,
            alt:"Taller de Liderazgo para Sierra Muebles"
          },
          {
            img:blog10_v3,
            alt:"Taller de Liderazgo para Sierra Muebles"
          },
          {
            img:blog10_v4,
            alt:"Taller de Liderazgo para Sierra Muebles"
          },
          {
            img:blog10_v5,
            alt:"Taller de Liderazgo para Sierra Muebles"
          },
          {
            img:blog10_v6,
            alt:"Taller de Liderazgo para Sierra Muebles"
          },
          {
            img:blog10_v7,
            alt:"Taller de Liderazgo para Sierra Muebles"
          },
          {
            img:blog10_v8,
            alt:"Taller de Liderazgo para Sierra Muebles"
          },
        ]
      }
    ]
  }
];
