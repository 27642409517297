import "./BlogPage.css"
import BlogBlock from "../components/BlogBlock"
import { blogs } from "../data/blogs"
import Footer from "../components/footer"

export default function BlogPage() {
  
  
  
  return (
    <>
      <h2 id="title-blog" className="text-center texto text-3xl p-6 underline" >Blog</h2>
      <div className="grid sm:grid-cols-2 grid-cols-1 justify-center items-center m-auto w-fit py-6 px-2 gap-6">
        {blogs.map((e,index)=>(
          <BlogBlock key={index} blog={e}/>
        )) }
      </div>
      <Footer/>
    </>
  )
}
