const data_colaboradores = [
    {
        id:1,
        nombre:"César Andrés Escalante Sifuentes",
        cpp:"29611",
        img:"cesar-png.png",
        des:[
            "Director de ESC Escucha Soluciona Crece",
            "Psicólogo clínico con formación Psicoanalítica y Cognitivo Postracional con más de 20 años de experiencia en el rubro.",
            "Magíster en neuropsicología.",
            "Docente y capacitador con más de 20 años dictando talleres y capacitaciones en empresas."
        ]
    },
    {
        id:2,
        nombre:"Melissa Alejandra Huaman Sanchez",
        img:"melissa-png.png",
        des:[
            "Bachiller en derecho corporativo.",
            "Facilitadora psicoterapéutica con formación psicoanalítica y cognitivo postracionalista."
        ]
    },
    {
        id:3,
        nombre:"Mateo del Campo",
        img:"mateo-png.png",
        des:[
            "Licenciado en administración y finanzas por la Universidad de Navarra con especialidad en Recursos Humanos.",
            "Facilitador psicoterapéutico con formación psicoanalítica y cognitivo postracionalista."
        ]
    },
    {
        id:4,
        nombre:"Mitzze Mayte Tupayachi Gamarra",
        img:"mayte-png.png",
        cpp:"54995",
        des:[
            "Psicóloga organizacional con especialidad en psicología empresarial.",
            "Especialización adicional como analista de selección y evaluación de personal."
        ]
    },
    {
        id:5,
        nombre:"Nijme Morcos Gonzales",
        cpp:"18164",
        img:"nijme-esc-png.png",
        des:[
            "Asesora principal de ESC Escucha Soluciona Crece.",
            "Psicóloga clínica con maestría en Psicología Clínica y de la Salud, así como estudios doctorales.",
            "Docente, consejera y capacitadora con más de 20 años de experiencia en el rubro."
        ]
    },
    {
        id:6,
        nombre:"Jeymer Iriate Obregón",
        img:"jeymer-png.png",
        des:[
            "Asesor principal de Escucha Soluciona Crece.",
            "Psicólogo organizacional especialista en selección, capacitación y desarrollo del talento humano con más de 12 años de experiencia en el rubro.",
            "Magíster en Ciencias Empresariales con especialidad en Gestión de Capital Humano.",
            "Magíster en Docencia Universitaria y Gestión Educativa.",
            "Docente, consultor y formador de habilidades blandas."
        ]
    }
]

export {data_colaboradores}