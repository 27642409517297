import "./WhIcon.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"

export default function WhIcon(){
    
    const handleClick1 = () => {
        window.location.href="https://api.whatsapp.com/send?phone=51920188134&text=Hola%2C%20estoy%20interesado%20en%20los%20servicios%20de%20ESC"
    }

    return (
        <>
        <span id="sticky-wh" onClick={handleClick1}>¡Escríbenos! <FontAwesomeIcon icon={faWhatsapp} bounce /></span>
        </>
    )
}