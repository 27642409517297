import React from 'react'
import "./ServicioPage.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckToSlot} from '@fortawesome/free-solid-svg-icons'
import { faBookBookmark, faPenRuler, faFilePen } from '@fortawesome/free-solid-svg-icons'
import { data_pri_serv } from '../data/serv-pri'
import { useState, useEffect } from 'react'
import Footer from "../components/footer"
import { useUbiContext } from '../components/UserContext'

function ServicioPage() {
  
  const {ubi,actualizarUbi}=useUbiContext()

  useEffect(()=>{
    if(ubi!== null){
      const targetElement = document.getElementById(ubi)
      if(targetElement){
        targetElement.scrollIntoView({behavior:"smooth"})
      }
      actualizarUbi(null)
    }
  },[])
  
  const [contPri, setContPri] = useState(0)

  const url = "https://bit.ly/esc-escucha-soluciona-crece"

  const whAction = ()=>{
    window.location.href = url
  }

  //State derivado
  const dataPri = data_pri_serv[contPri]
  
  return (
    <>
      <section className='pri-serv'>
       <h1>Consultoría en clima y cultura organizacional</h1>
       <button onClick={whAction}>Comunícate con nosotros</button> 
      </section>
      <section className="sec-serv">
        <div>
        <section className='sec-serv-container'>
          <aside className={contPri === 0 ? "active-pri-serv":"def-pri-serv"} onClick={()=>setContPri(0)}>
            <FontAwesomeIcon icon={faCheckToSlot}/>
            <h3>¿Qué implica esta consultoría?</h3>
          </aside>
          <aside className={contPri === 1 ? "active-pri-serv":"def-pri-serv"} onClick={()=>setContPri(1)}>
            <FontAwesomeIcon icon={faBookBookmark} />
            <h3>Evaluación de clima y cultura</h3>
          </aside>
          <aside className={contPri === 2 ? "active-pri-serv":"def-pri-serv"} onClick={()=>setContPri(2)}>
            <FontAwesomeIcon icon={faPenRuler} />
            <h3>Diseño de Estrategias</h3>
          </aside>
          <aside className={contPri === 3 ? "active-pri-serv":"def-pri-serv"} onClick={()=>setContPri(3)}>
            <FontAwesomeIcon icon={faFilePen} />
            <h3>Desarrollo de programas de bienestar laboral</h3>
          </aside>
        </section>
        </div>
        <section className='sec-serv-text' id='servicios'>
          <h2>{dataPri.titulo}</h2>
          {dataPri.lista ? 
          (<ul>
            {dataPri.des.map((e, index)=>{
              return (
               <li key={index}>{e}</li> 
              )
            })}
          </ul>)
          :
          (<>
            {dataPri.des.map((e, index)=>{
              return (
               <p key={index}>{e}</p>
              )
            })}
          </>)}
        </section>
      </section>
      <section className='ter-serv'>
        <section className='bloq-azul' id='bloq-az-1'>
            <span>Selección de Personal</span>
            <h2>Reclutamiento y selección de personal especializado</h2>
            <aside>
              <div>Análisis de las necesidades de la empresa, Definición del perfil detallado para el puesto.</div>
              <div>Difusión de la oferta, Recepción de solicitudes, Entrevistas de selección.</div>
              <div>Presentación de candidatos al cliente, Seguimiento y retroalimentación.</div>
              <div>Oferta de empleo, Integración del candidato.</div>
            </aside> 
        </section>
        <section className='bloq-celeste'>
            <span>Selección de Personal</span>
            <h2>Tipos de puestos que seleccionamos.</h2>
            <aside>
              <div>Profesionales, Analistas, Administrativos, Técnicos, Puestos de base.</div>
              <div>Operarios de producción.</div>
              <div>Operarios de apoyo logístico.</div>
              <div>Seguridad y Limpieza.</div>
            </aside> 
        </section>
        <section className='bloq-celeste'>
            <span>Capacitación, Talleres, Desarrollo y Gestión de Tiempos</span>
            <h2>Mejora el performance de tus equipos</h2>
            <aside>
              <div>Identificación de las necesidades requeridas por las distintas áreas.</div>
              <div>Implementación del programa utilizando herramientas como seminarios, talleres, entre otros.</div>
              <div>Seguimiento y evaluación del progreso de los participantes.</div>
              <div>Feedback y ajustes, Medición de resultados, elaboración de un informe final.</div>
            </aside> 
        </section>
        <section className='bloq-azul' id='bloq-az-2'>
            <span>Counselling y mediación de conflictos</span>
            <h2>La felicidad de tus trabajadores es el éxito de tu empresa</h2>
            <aside>
              <div>Nuestro servicio se preocupa por espacios de escucha activa para los trabajadores. Ello implica un servicio de consejería de urgencia, vinculado en temas relacionados a la salud mental, a fin de identificar y abordar adecuadamente el trabajo. </div>
              <div>Contamos con el servicio de consejería dirigido a prevenir la ansiedad, el estrés, la depresión y otros malestares emocionales.</div>
            </aside> 
        </section>
      </section>
      <Footer/>
    </>
  )
}

export default ServicioPage