import React from 'react'
import "./footer.css"
import esc_logo_largo from "../assets/esc-escucha-soluciona-crece-largo.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

function Footer() {

  const handleClickInstagram = () => window.location.href = "https://www.instagram.com/esc.consultora.recursoshumanos?igsh=MW9lM3N6MWgwazV3dw=="

  const handleClickLinkedIn = () => window.location.href = "https://www.linkedin.com/company/esc-consultora/"

  return (
    <footer id='footer'>
        <img src={esc_logo_largo} alt='ESC Escucha Soluciona Crece'/>
        <section>
            <p>
            Dirección
            <br></br>
            Jr. Cosmos 144, Santiago de Surco
            <br></br>
            Teléfono
            <br></br>
            +51 920 188 134
            <br></br>
            Correo
            <br></br>
            contacto@escconsultora.com
            </p>
            <div>
              <button onClick={handleClickInstagram}>
                <FontAwesomeIcon icon={faInstagram}/>
              </button>
              <button onClick={handleClickLinkedIn}>
                <FontAwesomeIcon icon={faLinkedinIn}/>
              </button>
            </div>
        </section>
    </footer>
  )
}

export default Footer
