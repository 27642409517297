const data_pri_serv= [
    {
        id:1,
        titulo:"¿Qué implica esta consultoría?",
        des:["Medimos el clima laboral de manera cualitativa y cuantitativa, analizando los factores que están influyendo positiva y negativamente en la empresa.","Sobre esta base, trabajamos para desarrollar e implementar estrategias y planes de acción para mejorar el clima laboral. En cuanto a la cultura laboral, diseñamos los valores y actitudes que definirán la personalidad de tu empresa y que serán integrados a la percepción que tiene el equipo sobre la empresa, con el objetivo de que sean debidamente transmitidos al resto de la comunidad."],
        lista:false
    },
    {
        id:2,
        titulo:"Evaluación del clima y cultura organizacional",
        des:[
            "Definir el objetivo de la evaluación.",
            "Seleccionar la metodología a través de encuestas y entrevistas.",
            "Aplicar las evaluaciones y ejecutar las entrevistas.",
            "Analizar los resultados.",
            "Redactar un informe con los resultados.",
            "Asesorar durante la implementación de las acciones concretas de mejora para el clima laboral.",
            "Monitorear y evaluar para medir el impacto de las acciones implmentadas."
        ],
        lista:true
    },
    {
        id:3,
        titulo:"Diseño e implementación de estrategias para mejorar el clima y cultura organizacional",
        des:[
            "Estudio y comprensión de la cultura actual a través de encuestas y análisis de datos.",
            "Definición de valores y objetivos que se desea promover y comunicar.",
            "Participación y empoderamiento a través de capacitaciones que permitan el cambio.",
            "Reconocimiento y recompensas.",
            "Liderazgo ejemplar.",
            "Medición del clima laboral.",
            "Medición de la satisfacción de los empleados."
        ],
        lista:true
    },
    {
        id:4,
        titulo:"Desarrollo de programas de bienestar laboral dirigidos al equilibrio y salud mental de los empleados",
        des:[
            "A través de una cultura de apoyo se busca brindar espacios de consejería para los trabajadores que requieran escucha y atención inmediata en sus problemas personales a fin de evitar que estos intervengan negativamente en el ejercicio de funciones laborales, así como en el clima de la empresa."
        ],
        lista:false
    }

]

export {data_pri_serv};