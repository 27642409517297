import React, { useState } from 'react'
import "./InicioPage.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { faPersonCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { faStar } from '@fortawesome/free-regular-svg-icons'
import { faPeopleGroup } from '@fortawesome/free-solid-svg-icons'
import Footer from '../components/footer'
import { useNavigate } from 'react-router-dom'
import { useUbiContext } from '../components/UserContext'
import t1 from "../assets/valeria_arbulu_esc.jpeg"
import t2 from "../assets/rodrigo_scerpella_esc.jpeg"
import t3 from "../assets/araceli_esc.jpeg"
import ilumina from "../assets/ILUMINA_COL.png"
import adex from "../assets/ADEX.png"
import ccll_contructora from "../assets/ccl_camara.png"
import videoValeria from "../assets/videob7_esc_1.mp4"
import videoRodrigo from "../assets/videob6_esc_1.mp4"

function InicioPage() {

    const {actualizarUbi} = useUbiContext()

    const url = "https://bit.ly/esc-escucha-soluciona-crece"

    const [video1,setVideo1] = useState(false)
    const [video2,setVideo2] = useState(false)

    const navigate = useNavigate()

    const navServicios = (link)=>{
        actualizarUbi(link)
        navigate("/servicio")
    }

  const whAction = ()=>{
    window.location.href = url
  }

  return (
    <div id='inicio'>
    <section className='pri-inicio'>
        <div>
            <h1>Invertir en la cultura laboral es invertir en el futuro de tu empresa</h1>
            <p>¡Hagamos que cada día de trabajo sea inspirador y productivo!</p>
            <button onClick={()=>whAction()}>Cotización Gratuita</button>
        </div>
    </section>
    <section className='sec-inicio'>
        <div className='sec-inicio-container'>
            <div>
            <aside className='texto'>
                <div>
                <FontAwesomeIcon icon={faGlobe}  className='i-globe'/>
                <h3>Medición de clima y cultura</h3>
                <p className='text-sm'>Evaluamos e identificamos mejoras para alinear los objetivos estratégicos de la empresa. </p>
                </div>
                <button onClick={()=>navServicios("servicios")}>Quiero saber más</button>
            </aside>
            <aside>
                <div>
                <FontAwesomeIcon icon={faGlobe} className='i-globe'/>
                <h3>Selección de personal</h3>
                <p className='text-sm'>Elegimos al candidato adecuado para tu organización.</p>
                </div>
                <button onClick={()=>navServicios("bloq-az-1")}>Quiero saber más</button>
            </aside>
            </div>
            <div>
            <aside>
                <div>
                <FontAwesomeIcon icon={faGlobe} className='i-globe'/>
                <h3>Charlas y capacitaciones</h3>
                <p className='text-sm'>Ofrecemos sesiones interactivas y formativas para fortalecer las habilidades y el ambiente laboral de tu equipo.</p>
                </div>
                <button onClick={()=>navServicios("bloq-az-2")}>Quiero saber más</button>
            </aside>
            <aside>
                <div>
                <FontAwesomeIcon icon={faGlobe} className='i-globe'/>
                <h3>Counselling y mediación de conflictos</h3>
                <p className='text-sm'>Brindamos apoyo personalizado a los trabajadores para superar desafíos y potenciar su desempeño laboral, además de intervenir en la resolución asertiva de problemas de comunicación dentro de la empresa</p>
                </div>
                <button onClick={()=>navServicios("bloq-az-2")}>Quiero saber más</button>
            </aside>
            </div>
        </div>
    </section>
    <section className='nuestra-mision nm1'>
        <section>
            <h2>«Nuestra Misión»</h2>
            <p>«Potenciamos el clima y cultura que tu empresa necesita, a través de soluciones  que se enfoquen en la salud mental»</p>
        </section>
        <aside></aside>
    </section>
    <h2 className='testimionios-tit'>Testimonios</h2>
    <aside className='testimonios'>
        <section>
            <div>
            <img src={t1}  alt='Valeria Arbulú'/>
            
                <h2>Valeria Arbulú <br></br>
                    <span>Gerente de Recursos Humanos - Sierra Muebles</span>
                </h2>
                <p>Me pareció un servicio bastante completo porque se dedicaron de lleno a cada uno de los colaboradores. Les dieron el tiempo que necesitaban y les abrieron el espacio al diálogo sin limitaciones. Además, algo que valoro mucho por encima de otras consultoras es el criterio clínico que aportan en sus evaluaciones y durante todo su enfoque, asegurando el cuidado integral de cada colaborador.</p>
                <button className='w-3/4 text-center py-2 bg-amarillo texto texto-azul rounded-lg shadow-lg my-2 m-auto transition-all hover:scale-95' onClick={(e)=>setVideo1(!video1)}>{video1 ? "Ocultar video":"Ver video"}</button>
                <div className={`${video1 ? "h-96":"h-0"} overflow-hidden transition-all`}>
                    <video
                        id="myVideo"
                        controls
                        className={`h-96 m-auto rounded-lg shadow`}
                    >
                        
                    <source src={videoValeria} type="video/mp4" />
                    Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </section>
        <section>
            <div>
            <img src={t2}  alt='Rodrigo Scerpella'/>
            
                <h2>Rodrigo Scerpella <br></br>
                    <span>Business developer - Pacific</span>
                </h2>
                <p>El principal cambio que veo es que las personas automáticamente entendieron que todo el trabajo que realizamos con la consultora es para su beneficio profesional y personal. Se sienten más valorados, reconocidos, escuchados y considerados. El ambiente ya empezó a sentirse diferente y aún ni siquiera hemos empezado las capacitaciones específicas. Estos primeros cambios ya nos hacen ver a futuro con mucho entusiasmo.</p>
                <button className='w-3/4 text-center py-2 bg-amarillo texto texto-azul rounded-lg shadow-lg my-2 m-auto transition-all hover:scale-95' onClick={(e)=>setVideo2(!video2)}>{video2 ? "Ocultar video":"Ver video"}</button>
                <div className={`${video2 ? "h-96":"h-0"} overflow-hidden transition-all`}>
                    <video
                        id="myVideo"
                        controls
                        className={`h-96 m-auto rounded-lg shadow`}
                    >
                        
                    <source src={videoRodrigo} type="video/mp4" />
                    Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </section>
        <section>
            <div>
            <img src={t3}  alt='Araceli'/>
            
                <h2>Araceli <br></br>
                    <span>Supervisora de laboratorio - Pacific</span>
                </h2>
                <p>Supervisora de laboratorio
                Recomiendo mucho a ESC. Su trabajo es muy personalizado, efectivo y profesional. Toman a la empresa como un socio aliado y se involucran en cada paso del proceso. Además, consideran y valoran a cada uno de los colaboradores de forma integral.</p>
            </div>
        </section>
    </aside>
    <aside className='colaboraciones'>
        <h2>Alianzas y Convenios</h2>
        <section>
            <div>
                <img src={ilumina} alt='ilumina'/>
                <aside>Asociación sin fines de lucro</aside>
            </div>
            <div><img src={adex} alt='adex'/></div>
            <div><img src={ccll_contructora} alt='ccll_contructora'/></div>
        </section>
    </aside>
    <section className='opc-inicio'>
        <section>
            <FontAwesomeIcon icon={faPersonCircleCheck} className='icon-inicio'/>
            <p>Ayuda a atraer nuevos empleados y retener a los más destacados.</p>
            <button onClick={()=>whAction()}>Infórmate</button>
        </section>
        <section>
            <FontAwesomeIcon icon={faStar} className='icon-inicio'/>
            <p>Facilita el comrpomiso y la retención de la fuerza de trabajo.</p>
            <button onClick={()=>whAction()}>Capacítate</button>
        </section>
        <section>
            <FontAwesomeIcon icon={faPeopleGroup} className='icon-inicio'/>
            <p>Los empleados mejoran su rendimiento, son más eficientes y productivos.</p>
            <button onClick={()=>whAction()}>Conócenos</button>
        </section>
    </section>
    <Footer/>
    </div>
  )
}

export default InicioPage
