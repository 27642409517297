import { Link } from "react-router-dom"

export default function BlogBlock({blog}) {
  return (
    <>
    <div className="md:flex rounded-lg shadow-md md:h-60 overflow-hidden md:max-w-lg">
          <img
            className="md:h-full md:w-48 w-full h-36 object-cover object-center cursor-pointer"
            src={blog.img.content}
            alt={blog.img.alt}
          />
          <div className="py-5 px-5 flex flex-col items-center justify-between line-clamp-2 w-full">
            <div>
            <h2 className="subtitulo line-clamp-2 texto-amarillo ">
              {blog.title}
            </h2>
            <p className="texto text-sm texto line-clamp-3 ">
              {blog.body.find(e => e.type === "text").content}
            </p>
            </div>
            <Link to={blog.url} className="texto text-center py-1 px-3 mt-3 rounded-lg bg-amarillo cursor-pointer w-full transition-all hover:scale-95 texto-azul">Leer blog</Link>
          </div>
        </div>
    </>
  )
}
