import { createContext, useContext, useState } from "react";
import React from "react";
const UbiContext = createContext();

export function UserContext({ children }) {
  const [ubi, setUbi] = useState(null);
  const actualizarUbi = (nuevoUbi) => {
    setUbi(nuevoUbi);
  };

  return (
        <UbiContext.Provider value={{ ubi, actualizarUbi }}>
          {children}
        </UbiContext.Provider>
  );
}

export const useUbiContext = ()=>{
    return useContext(UbiContext)
}