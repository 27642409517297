import React from "react";
import logo from "../assets/esc_logo.png"
import { NavLink, useNavigate} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import "./Nav.css"

export default function Nav() {

  const url = "https://bit.ly/esc-escucha-soluciona-crece"

  const navigate = useNavigate()

  const whAction = ()=>{
    window.location.href = url
  }

  const openNav = () => {
    const nav = document.getElementById("mobile-nav");
    nav.style.width = "100%";
  };

  const closeNav = () => {
    const nav = document.getElementById("mobile-nav");
    nav.style.width = "0%";
  };

  return (
    <header>
      <div className="logo" onClick={()=>navigate("/inicio")}>
        <img src={logo} alt="ESC Escucha Soluciona Crece" />
      </div>
      <nav>
        <ul className="lista-nav">
          <li>
            <NavLink
              className={({ isActive }) => {
                if (isActive) {
                  return "active";
                } else {
                  return "link";
                }
              }}
              to={"/inicio"}
            >
              INICIO
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => {
                if (isActive) {
                  return "active";
                } else {
                  return "link";
                }
              }}
              to={"/servicio"}
            >
              SERVICIOS
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => {
                if (isActive) {
                  return "active";
                } else {
                  return "link";
                }
              }}
              to={"/nuestro-equipo"}
            >
              NUESTRO EQUIPO
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => {
                if (isActive) {
                  return "active";
                } else {
                  return "link";
                }
              }}
              to={"/porque-esc"}
            >
              ¿POR QUÉ ESC?
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => {
                if (isActive) {
                  return "active";
                } else {
                  return "link";
                }
              }}
              to={"/blog"}
              onClick={()=>closeNav()}
            >
              BLOG
            </NavLink>
          </li>
          <li className="link-contactanos" onClick={()=>whAction()}>
            Contáctanos
          </li>
        </ul>
      </nav>
      <FontAwesomeIcon
        icon={faBars}
        className="menu"
        onClick={() => {
          openNav();
        }}
      />
      <div className="overlay" id="mobile-nav">
        <FontAwesomeIcon
          icon={faXmark}
          className="cancel-nav"
          onClick={() => closeNav()}
        />
        <div className="overlay-content">
          <li>
            <NavLink
              className={({ isActive }) => {
                if (isActive) {
                  return "active";
                } else {
                  return "link";
                }
              }}
              to={"/inicio"}
              onClick={()=>closeNav()}
            >
              INICIO
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => {
                if (isActive) {
                  return "active";
                } else {
                  return "link";
                }
              }}
              to={"/servicio"}
              onClick={()=>closeNav()}
            >
              SERVICIOS
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => {
                if (isActive) {
                  return "active";
                } else {
                  return "link";
                }
              }}
              to={"/nuestro-equipo"}
              onClick={()=>closeNav()}
            >
              NUESTRO EQUIPO
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => {
                if (isActive) {
                  return "active";
                } else {
                  return "link";
                }
              }}
              to={"/porque-esc"}
              onClick={()=>closeNav()}
            >
              ¿POR QUÉ ESC?
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => {
                if (isActive) {
                  return "active";
                } else {
                  return "link";
                }
              }}
              to={"/blog"}
              onClick={()=>closeNav()}
            >
              BLOG
            </NavLink>
          </li>
          <li className="link-contactanos" onClick={()=>whAction()}>
            Contáctanos
          </li>
        </div>
      </div>
    </header>
  );
}
