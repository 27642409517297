import React from 'react'
import Footer from "../components/footer"
import "./PorqueESC.css"
import img from "../assets/servicio_de_atencion_esc.png"

function PorqueESC() {
  
  const url = "https://bit.ly/esc-escucha-soluciona-crece"

  const whAction = ()=>{
    window.location.href = url
  }
  
  return (
   <>
    <main className='pri-pqesc'>
      <h1>¿Por qué es importante la psicología
      en RR.HH?</h1>
      <p>La psicología desempeña un papel crucial en el ámbito de los recursos humanos (RRHH) debido a su capacidad para mejorar la gestión del talento y el bienestar de los empleados. Comprender el comportamiento humano permite a los profesionales de RRHH seleccionar a los candidatos más adecuados, diseñar programas de capacitación eficaces y fomentar un entorno de trabajo positivo. </p>
      <button onClick={()=>whAction()}>Contáctanos</button>
    </main>
    <section className='sec-pqesc'>
      <h2>El Enfoque de ESC</h2>
      <p>«Experiencia y conocimiento en el mercado laboral desde
      una perspectiva tanto organizacional como clínica.»</p>
    </section>
    <section className='ter-pqesc'>
      <section>
        <img src={img} alt='empresarios RRHH'/>
        <aside>
          <div>
          <p>Servicio de atención clínica a través de «consejería de urgencia» y «departamento de mediación de conflictos»</p>
          <br/>
          <p>Enfoque personalizado y adaptado a las necesidades y exigencias legales de cada empresa.</p>
          </div>
          <span onClick={()=>whAction()}>Contáctanos</span>
        </aside>
      </section>
    </section>
    <Footer/>
   </> 
  )
}

export default PorqueESC