import React from 'react'
import Footer from "../components/footer.js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { data_colaboradores } from '../data/data-colaboradores.js'
import { useState} from 'react'
import "./NuestroEquipo.css"
import cesar from "../assets/03 Cesar.png"
import melissa from "../assets/01 meli.png"
import mateo from "../assets/02 mateo.png"
import mayte from "../assets/04 Mayte.png"
import nijme from "../assets/nijme-esc-png.png"
import jeymer from "../assets/jeymer-png.png"

function NuestroEquipo() {
  
    const [cont,setCont] = useState(0)

    function getImage(n){
        switch(n){
            case 1:
                return cesar;
            case 2:
                return melissa;
            case 3:
                return mateo;
            case 4:
                return mayte;
            case 5:
                return nijme;
            case 6:
                return jeymer;
            default:
                return cesar;
        }
    }
    
  
    return (
    <>
        <div className='nuestro-equipo'>
            <h1>Dispuestos a asesorarte en el área de RR.HH</h1>
            <section className='display'>
                <button className={cont > 0 ? "active-arrow":"def_arrow"} 
                onClick={()=>{
                    if(cont > 0){
                        setCont(prevCont => prevCont - 1)
                    }
                }}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                
                <main>
                    {data_colaboradores.map((e,index)=>{
                        
                        return (
                            <div key={index} className={cont === index ? "active-colaborador colaborador":"def-colaborador colaborador"}>
                                <aside>
                                    <h2>{e.nombre}</h2>
                                    {e?.cpp ? (<>
                                        <span>CPP: {e.cpp}</span>
                                    </>):(<></>)}
                                    <ul>
                                        {e.des.map((e,index)=>{
                                            return (<li key={index}>{e}</li>)
                                        })}
                                    </ul>
                                </aside>
                                <img src={getImage(e.id)} alt={e.nombre}/>
                            </div>
                        )
                    })}
                </main>
                <button className={cont < 5 ? "active-arrow":"def_arrow"}
                onClick={()=>{
                    if(cont < 5){
                        setCont(prevCont => prevCont + 1)
                    }
                }}>
                    <FontAwesomeIcon icon={faArrowRight}/>
                </button>
                
            </section>
        </div>
        <section className='guia-colab'>
            {data_colaboradores.map((e,index)=>{
                return(
                    <div key={index} className={cont === index ? "active-circle":"def-circle"} 
                    onClick={()=>setCont(index)}></div>
                )
            })}
        </section>
        <Footer/>
    </>
  )
}

export default NuestroEquipo